<template>
  <div>
    <van-nav-bar
        title="标题"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-field
        v-model="order.realName"
        label="商户"
        name="pattern"
        placeholder="请选择商户"
        readonly
    >
    </van-field>
    <!-- 通过 validator 进行函数校验 -->
    <van-field
        v-model="order.userPhone"
        label="手机号码"
        name="validator"
        placeholder="请选择商户"
        readonly
    />
    <van-field
        v-model="order.deliveryAddress"
        label="送货地址"
        name="asyncValidator"
        placeholder="请输入送货地址"
    />
    <van-field
        v-model="order.deliveryAddress"
        is-link
        readonly
        label="送货地址"
        placeholder="请选择所在地区"
        @click="selectAddressPopupShow = true"
    />
    <van-popup v-model="selectAddressPopupShow" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          :field-names="fieldNames"
          @close="selectAddressPopupShow = false"
          @finish="onFinish"
          @change="onChange"
      />
    </van-popup>
    <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="order.orderDatetimeStr"
        label="时间选择"
        placeholder="点击选择时间"
        @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
          v-model="orderDatetime"
          type="datetime"
          title="选择完整时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      商品信息
    </van-divider>

    <div ref="container" style="padding-bottom: 35px">
      <van-sticky :container="container" :offset-top="50">
        <div style="display: flex; color: #ee0a24; text-align: center; margin-bottom: 15px;">
          <div style="width: 40%;">
            <p class="van-coupon__condition" style="font-size: 16px">{{ order.totalPrice }}<span>元</span></p>
          </div>
          <div style="width: 40%">
            <p class="van-coupon__condition" style="font-size: 16px">{{ order.totalNum }}</p>
          </div>
          <div style="text-align: right; width: 30%; padding-right: 10px; padding-top: 8px;">
            <van-button v-if="order.isMerge != 1" size="mini" type="primary" @click="onBeforeSubmit">提交修改</van-button>
            <van-button v-if="order.status != 1 && order.isMerge != 1" size="mini" type="primary" style="margin-left: 3px" @click="submitForPayOrder">支付</van-button>
          </div>
        </div>
      </van-sticky>
      <van-tabs v-model="tabActive">
        <van-tab title="明细">
          <div v-for="item in orderInfos" :key="item.id" :name="item.id">
            <van-card
                :price="(Number(item.price) * 100 / 100).toFixed(2)"
                :desc="item.sku"
                :title="item.storeName"
                :thumb="item.image"
                :num="item.number"
            >
              <template #footer>
                <van-button v-if="order.isMerge != 1" type="warning" size="mini" @click="editOrderInfo(item)">修改
                </van-button>
                <van-button v-if="order.isMerge != 1" type="warning" size="mini" @click="deleteOrderInfo(item)">删除
                </van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
        <van-tab title="付款时间线">
          <van-empty v-if="timelines.length == 0" description="未找到支付详情" />
          <van-steps direction="vertical" :active="0">
              <van-step v-for="item in timelines" :key="item.id" :name="item.id">
                <h3>{{item.payTime}}</h3>
                <div>支付金额：{{item.payPrice}}</div>
                <div>支付方式：{{item.payType}}</div>
                <div>备至：{{item.mark}}</div>
              </van-step>
          </van-steps>
        </van-tab>
      </van-tabs>

    </div>
    <van-popup v-model="orderInfoEditPopupShow" :style="{ width: '80%' }">
      <van-field v-model="editItem.price" type="number" @input="onChangeForTotalPrice"
                 @focus="clearText" label="价格"
                 placeholder="请输入价格"
      />
      <van-field v-model="editItem.number" type="number" @input="onChangeForTotalPrice"
                 @focus="clearPrice" label="数量"
                 placeholder="请输入数量"
      />
    </van-popup>
    <paymentPopup ref="paymentPopup" ></paymentPopup>
    <payPopup ref="payPopup" ></payPopup>

  </div>

</template>

<script>
import {
  Icon,
  Button,
  Field,
  Popup,
  Card,
  Stepper,
  Divider,
  DatetimePicker,
  Sticky, Loading, Image, Notify, Dialog, Toast, CouponCell, NavBar, Cascader, Step, Steps, Empty, Tab, Tabs,
} from 'vant';
import paymentPopup from "@/components/paymentPopup";
import payPopup from "@/components/payPopup";

import * as adminOrderService from "@/api/admin/order";
import * as getDataService from "@/api/serverData/getServerData";

export default {
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [Divider.name]: Divider,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Loading.name]: Loading,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Cascader.name]: Cascader,
    paymentPopup, payPopup,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Empty.name]: Empty,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },

      container: null,
      editItem: {},
      orderInfoEditPopupShow: false,
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      order: {},
      orderDatetime: new Date(),
      orderInfoPopupShow: false,
      orderInfos: [],
      tabActive : '',
      timelines: [],
      timelineActive: 0
    };
  },
  mounted() {
    this.container = this.$refs.container;
  },
  created() {
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
    if (this.$route.params && this.$route.params.orderId) {
      this.initData()
    }
  },
  methods: {
    initData() {
      adminOrderService.getAccountOrderById(this.$route.params.orderId).then(res => {
        if (res.status === 200) {
          this.order = res.data
          if (res.data !== undefined) {
            console.log(this.order)
            this.order.orderDatetimeStr = this.order.orderTime
          }
        }
      });
      adminOrderService.getAccountOrderInfo(this.$route.params.orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      })
      adminOrderService.listAccountOrderTimeline(this.$route.params.orderId).then(res => {
        if (res.status === 200) {
          this.timelines = res.data
          this.timelineActive = this.timelines.length - 1
        }
      })
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    clearText() {
      this.editItem.price = ''
    },
    clearPrice() {
      this.editItem.number = ''
    },
    onChangeForTotalPrice() {
      let amount = 0;
      let number = 0;
      this.orderInfos.forEach(item => {
        amount += item.price * Number(item.number);
        number += Number(item.number);
      })
      this.order.totalNum = Number(number);
      this.order.totalPrice = amount;
      const _this = this.$refs.paymentPopup
      _this.totalPrice = this.order.totalPrice
      if (_this.paymentStatusChecked === '1' || _this.paymentStatusChecked === '2') {
        _this.payPrice = this.order.totalPrice
      }
    },
    editOrderInfo(item) {
      this.editItem = item
      this.orderInfoEditPopupShow = true;
    },
    deleteOrderInfo(item) {
      if (this.orderInfos.length === 1) {
        Toast("订单详情至少保留一项，如需删除此订单请返回列表")
        return
      }
      for (let i = 0; i < this.orderInfos.length; i++) {
        if (this.orderInfos[i].id === item.id) {
          this.orderInfos.splice(i, 1)
        }
      }
      this.onChangeForTotalPrice()
    },
    onConfirm(time) {
      this.order.orderDatetimeStr = `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()} ${time.getHours()}:${time.getMinutes()}`;
      this.order.orderDatetime = time;
      this.showPicker = false;
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfo(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    submitForEditOrder() {
      this.order.accountOrderInfos = this.orderInfos;
      this.order.payPrice = this.$refs.paymentPopup.payPrice
      if (Number(this.$refs.paymentPopup.payPrice) > Number(0)) {
        this.order.payType = this.$refs.paymentPopup.paymentTypeChecked
      } else {
        this.order.payType = null
      }
      this.order.statusOfFront = this.$refs.paymentPopup.paymentStatusChecked
      this.order.mark = this.$refs.paymentPopup.mark
      Dialog.confirm({
        title: '确认修改此订单吗？',
        message: '请仔细检查～',
      })
          .then(() => {
            adminOrderService.editOrder(this.order).then(res => {
              this.$refs.paymentPopup.isPaymentShow = false
              this.initData()
              Notify({type: 'success', message: '修改成功'});
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
    },
    onBeforeSubmit() {
      this.onChangeForTotalPrice()
      const _this = this.$refs.paymentPopup
      _this.isPaymentShow = true
    },
    submitForPayOrder() {
      const _this = this.$refs.payPopup
      _this.orderId=this.order.id
      _this.totalPrice =this.order.totalPrice
      _this.paymentPrice =this.order.payPrice
      _this.isPaymentShow = true
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      console.log(selectedOptions)
      this.selectAddressPopupShow = false;
      this.order.address = selectedOptions.map((option) => option.n).join(' ');
      this.order.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    },
    onChange({selectedOptions}) {
      console.log(selectedOptions)
      this.order.address = selectedOptions.map((option) => option.n).join(' ');
      this.order.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
