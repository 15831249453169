<template>
  <van-popup v-model="isPaymentShow" position="bottom" :style="{ height: '40%' }">
    <van-form @submit="onSubmitForOrder">
      <van-cell-group inset>
        <van-field name="radio" label="支付">
          <template #input>
            <van-radio-group v-model="paymentStatusChecked" direction="horizontal" @change="onPaymentStatusChange">
              <van-radio name="0">欠账</van-radio>
              <van-radio name="1">付清</van-radio>
              <van-radio name="2">结账</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="radio" label="支付方式">
          <template #input>
            <van-radio-group v-model="paymentTypeChecked" direction="horizontal">
              <van-radio name="微信" style="margin-top: 2px;">微信</van-radio>
              <van-radio name="现金" style="margin-top: 2px;">现金</van-radio>
              <van-radio name="支付宝" style="margin-top: 2px;">支付宝</van-radio>
              <van-radio name="银行卡" style="margin-top: 2px;">银行卡</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
          订单金额：{{ totalPrice }}，已支付{{ paymentPrice }}元，需支付{{ totalPrice - paymentPrice }}元
        </van-notice-bar>
        <van-field v-model="payPrice"
                   size="small" label="支付金额" rows="1" type="number" style="margin-bottom: 3px;"/>
        <van-field
            v-model="mark"
            rows="2"
            autosize
            label="留言"
            type="textarea"
            maxlength="50"
            placeholder="请输入留言"
            show-word-limit
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Popup,
  Stepper,
  SubmitBar,
  Divider,
  DatetimePicker,
  Sticky,
  Notify,
  Cascader,
  CouponCell, NavBar, Radio, RadioGroup, NoticeBar, Dialog
} from 'vant';

import * as adminOrderService from "@/api/admin/order";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      verifySuccess: true,
      orderId: null,
      isPaymentShow: false,
      totalPrice: 0,
      paymentPrice: 0,
      payPrice: 0,
      mark: null,
      paymentStatusChecked: '0',
      paymentTypeChecked: '微信',
    };
  },
  methods: {
    verifyPayment() {
      // 如果支付状态为付清 则支付金额必须等于订单金额
      // 如果支付状态为欠账 则支付金额必须小于订单金额
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      if (Number(this.payPrice) <= Number(0)) {
        Toast("请输入支付金额")
        this.verifySuccess = false
        return
      }
      if ((Number(this.paymentPrice) + Number(this.payPrice)) > Number(this.totalPrice)) {
        Toast("支付金额不能高于需支付金额")
        this.verifySuccess = false
        return
      }
      // 付清
      if (this.paymentStatusChecked === '1') {
        if ((Number(this.payPrice) + Number(this.paymentPrice)) !== Number(this.totalPrice)) {
          Toast("支付金额要等于订单金额")
          this.verifySuccess = false
          return
        }
      } else if (this.paymentStatusChecked === '0') {
        if ((Number(this.payPrice) + Number(this.paymentPrice)) === Number(this.totalPrice)) {
          Toast("支付金额不能等于订单金额")
          this.verifySuccess = false
          return
        }
      }

      this.verifySuccess = true
    },
    onSubmitForOrder() {
      this.verifyPayment()
      if (!this.verifySuccess) {
        return
      }
      let paramObj = {
        id: this.orderId,
        payPrice: this.payPrice,
        statusOfFront: this.paymentStatusChecked,
        mark: this.mark,
        payType: this.paymentTypeChecked
      }

      Dialog.confirm({
        title: '确认此操作吗？',
        message: '请仔细检查～',
      })
          .then(() => {
            adminOrderService.payForOrder(paramObj).then(res => {
              this.isPaymentShow = false
              this.$parent.initData()
              Notify({type: 'success', message: '操作成功'});
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    onPaymentStatusChange() {
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      if (this.paymentStatusChecked === '2' || this.paymentStatusChecked === '1') {
        this.payPrice = Number(this.totalPrice) - Number(this.paymentPrice)
      }
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
